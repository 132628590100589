<template>
  <div class="content">
    <div class="center-bar" v-if="formInputItems.extFields.length > 0">
      <b-tabs>
        <b-tab>
          <template #title>
            {{ $g("searchElector") }}
          </template>
        </b-tab>
      </b-tabs>
      <KValidationObserver ref="observer" class="content-page">
        <div class="content-form">
          <div class="content-main">
            <div class="content-party pt-3">
              <div class="content-group">
                <k-custom-fields
                  ref="customFields"
                  v-model="formInputItems"
                  :plaintext="false"
                />
                <k-form-group
                  v-for="(item, index) in popwindowTextBoundaryTypeArray"
                  :key="index"
                  :label-class="item.isMandatory ? `require-mark` : ``"
                  :label-suffix="item.displayName"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :no-locale="true"
                  label-for="noneBind"
                >
                  <KValidationProvider
                    :rules="{ required: item.isMandatory }"
                    v-slot="{ errors }"
                    slim
                    :name="`popwindowText${index}`"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <div
                      class="input-search"
                      @click="popwindowTextClick(item, index)"
                    >
                      <k-form-input
                        :ref="`popwindowText${index}`"
                        v-model="item.inputValue"
                        type="text"
                        :plaintext="item.isOnlyView"
                        disabled
                      />
                      <b-icon icon="search" />
                    </div>
                  </KValidationProvider>
                </k-form-group>
              </div>
              <div class="text-center search-dialog-footer">
                <k-button @click="onSearch" variant="primary">
                  buttons.search
                </k-button>
                <k-button @click="onClear" variant="default" class="ml-2">
                  buttons.clear
                </k-button>
              </div>
            </div>
          </div>
        </div>
      </KValidationObserver>
    </div>
    <div class="content-main" v-if="selectableTableShow">
      <b-tabs>
        <b-tab>
          <template #title>
            {{ $g("searchResults") }}
          </template>
        </b-tab>
      </b-tabs>
      <div class="content-party overflow-auto pb-1">
        <k-alert-msg ref="lblMsg" />
        <konn-grid
          ref="selectableTableVoterAuthentication"
          :show-all-checkbox="false"
          :has-check-box="false"
          :data-source="dataSource"
          :get-condition="getCondition"
          :class="{ 'table-show-search-area': showThisSearchArea }"
          :grid-sort-desc="sortDesc"
          grid-sort-by="sortOrder"
          sort-icon-left
          :has-action="false"
          :auto-bind="false"
          @items-get="itemsGet"
          @all-get="allGet"
        >
          <template #cell(firstName)="row">
            <a href="javascript:void(0)" @click="onClose(row.item)">
              {{ row.item.firstName }}
            </a>
          </template>
          <template #cell(lastName)="row">
            <a href="javascript:void(0)" @click="onClose(row.item)">
              {{ row.item.lastName }}
            </a>
          </template>
        </konn-grid>
      </div>
    </div>
    <k-popwindow
      id="model-popwindowText"
      :title="$g(popwindowTextTitle)"
      @ok="onPopDivisionSelected"
    >
      <PopWindowText
        ref="popwindowText"
        :get-api="popwindowTextApi"
        :condition="popwindowTextApiCondition"
        :show-popwindow-text-enum="ShowPopwindowTextEnum"
        :show-popwindow-text="ShowPopwindowText"
      />
    </k-popwindow>
  </div>
</template>

<script>
import {
  getBoundaryPage,
  getSearchVoterKeys,
  getVoterBoundaryType,
  searchElectorVoter,
  getSearchSubdivisionPage,
  checkElectorId,
} from "../../../api/application";
import { storeage } from "fe-infrastractures";
import PopWindowText from "../../application/components/popwindowText.vue";

export default {
  components: { PopWindowText },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    streetTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    streetPosts: {
      type: Array,
      default() {
        return [];
      },
    },
    stateTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectableTableShow: false,
      sortDesc: false,
      showThisSearchArea: true,
      totalCount: 0,
      formInputItems: {
        extFields: [],
      },
      condition: {},
      popwindowTextBoundaryTypeArray: [],
      popwindowTextArray: [],
      popwindowTextTitle: "",
      popwindowTextApi: null,
      popwindowTextApiCondition: [],
      popwindowTextInputRef: "",
      popwindowTextInputIndex: 0,
      ShowPopwindowText: 0,
      ShowPopwindowTextEnum: {
        None: 0,
        Subdivision: 1,
        BoundaryType: 2,
      },
      // select
      selectedId: "",
    };
  },
  created() {
    this.bindFormInput();
  },
  computed: {
    dataSource() {
      return searchElectorVoter;
    },
  },
  methods: {
    popwindowTextClick(item, input) {
      this.selectedId = item.id;
      this.popwindowTextInputRef = `popwindowText${input}`;
      this.popwindowTextInputIndex = input;
      this.ShowPopwindowText = item.showPopwindowTextEnum;
      this.popwindowTextApiCondition = {
        ...item.condition,
        ...{ electionId: this.formData.electionid },
      };
      this.popwindowTextTitle =
        item.showPopwindowTextEnum == 1 ? "Subdivision" : "Boundaries";
      this.popwindowTextApi = item.api;
      this.$bvModal.show("model-popwindowText");
    },
    onPopDivisionSelected() {
      let item = this.$refs.popwindowText.getSelected()[0];
      let data = this.switchShowPopwindowText(this.ShowPopwindowText, item);
      this.$set(
        this.popwindowTextBoundaryTypeArray[this.popwindowTextInputIndex],
        "inputValue",
        data.name
      );
    },
    switchShowPopwindowText(e, item) {
      let data = {
        name: "",
      };
      switch (e) {
        case this.ShowPopwindowTextEnum.Subdivision:
          data.name = item.divisionName;
          this.condition.subdivisionId = item.subdivisionId;
          break;
        case this.ShowPopwindowTextEnum.BoundaryType:
          data.name = item.boundaryName;
          if (this.condition.boundaryList) {
            this.condition.boundaryList.forEach((c, index, arr) => {
              if (c.boundaryTypeId == this.selectedId) {
                arr.splice(index, 1);
              }
            });
          }
          this.boundaryTypeSetConditionBoundaryList({
            boundaryId: item.boundaryId,
            boundaryTypeId: this.selectedId,
          });
          break;
        default:
          break;
      }
      return data;
    },
    boundaryTypeSetConditionBoundaryList(item) {
      if (!this.condition.boundaryList) {
        this.condition.boundaryList = [{ ...item }];
      } else {
        this.condition.boundaryList.push({ ...item });
      }
    },
    allGet(data) {
      if (data.data.total == 0 || data.data.total > 100) {
        this.$refs.lblMsg.message(2, data.message);
      }
    },
    itemsGet(items) {
      if (items.length == 1) {
        this.onClose(items[0]);
      }
    },
    bindFormInput() {
      getSearchVoterKeys().then((result) => {
        this.setBoundaryType(result);
        let data = result.data.filter(
          (c) => c.key != "Boundary" && c.key != "Subdivision" && c.activate
        );
        data.forEach((c) => {
          this.inputFor(c);
        });
        this.formInputItems.extFields = [...data];
      });
    },
    inputFor(c) {
      let toLowerCase = c.key.toLowerCase();
      if (this.formData[toLowerCase] || toLowerCase == "residentiallocality") {
        if (toLowerCase == "residentiallocality") {
          this.formInputItems[c.key] = this.formData["residentialcity"];
        } else {
          this.formInputItems[c.key] = this.formData[toLowerCase];
        }
      }
      c.options = this.getSwitchSelectOptions(c.key);
      c.type = c.fieldType == 0 ? 1 : c.fieldType;
      c.fieldName = c.key;
      c.isMandatory = c.required;
      c.isOnlyView = false;
      c.displayName = c.label;
    },
    buildBoundaryType(Boundary) {
      getVoterBoundaryType(this.formData.electionid).then((result) => {
        result.data.forEach((c) => {
          Boundary.label = c.typeCode;
          Boundary.condition = { boundaryTypeId: c.id };
          Boundary.showPopwindowTextEnum =
            this.ShowPopwindowTextEnum.BoundaryType;
          c = { ...c, ...Boundary };
          this.inputFor(c);
          this.popwindowTextBoundaryTypeArray.push(c);
        });
      });
    },
    setBoundaryType(result) {
      let Boundary = result.data.find((c) => c.key == "Boundary" && c.activate);
      let Subdivision = result.data.find(
        (c) => c.key == "Subdivision" && c.activate
      );
      if (Subdivision) {
        Subdivision.api = getSearchSubdivisionPage;
        Subdivision.showPopwindowTextEnum =
          this.ShowPopwindowTextEnum.Subdivision;
        this.popwindowTextArray.push(Subdivision);
        let s = {
          typeCode: "Subdivision",
          displayName: "Subdivision",
          ...Subdivision,
        };
        this.inputFor(s);
        this.popwindowTextBoundaryTypeArray.push(s);
      }
      if (Boundary) {
        Boundary.api = getBoundaryPage;
        this.popwindowTextArray.push(Boundary);
        this.buildBoundaryType(Boundary);
      }
    },
    getSwitchSelectOptions(text) {
      let options = [];
      switch (text) {
        case "ResidentialStreetType":
          options = this.streetTypes;
          break;
        case "ResidentialStreetPre":
          options = this.streetPosts;
          break;
        case "ResidentialStreetPost":
          options = this.streetPosts;
          break;
        case "ResidentialState":
          options = this.stateTypes;
          break;
        default:
          break;
      }
      return options;
    },
    getCondition() {
      let c = this.$refs.customFields.getInputValue(
        {},
        this.formInputItems.extFields
      );
      return {
        ...c,
        ...this.condition,
        ...{ electionId: this.formData.electionid },
      };
    },
    onClear() {
      this.selectableTableShow = false;
      this.popwindowTextInputRef = "";
      this.popwindowTextInputIndex = 0;
      this.ShowPopwindowText = 0;
      this.condition = {};
      this.formInputItems.extFields.forEach((e) => {
        this.$set(e, "value", null);
      });
      this.$refs.customFields.setInputValue();
      this.$refs.observer.reset();
      this.popwindowTextBoundaryTypeArray.forEach((e) => {
        this.$set(e, "inputValue", null);
      });
    },
    async onSearch() {
      this.selectableTableShow = true;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$refs.selectableTableVoterAuthentication?.clearFields();
        this.$refs.selectableTableVoterAuthentication?.refresh();
      }
    },
    onClose(item) {
      console.log(item);
      checkElectorId({
        electorId: item.electorId,
        pollId: this.$route.query.id,
      }).then((res) => {
        if (res.data.info) {
          this.$emit("select-successful", item);
        } else {
          this.$refs.lblMsg.message(3, this.$g("voter-is-already"));
        }
      });
    },
  },
};
</script>

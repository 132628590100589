import http from "../../../../fe-infrastractures/src/utils/http";

export const workerActionType = {
  add: 1,
  edit: 2,
  view: 3,
};

export const workerModuleType = {
  bauProfile: 1,
  eventProfile: 2,
  workerPortalApplication: 3,
  workerPortalApply: 4
};

export const workerFieldNameEnums = {
  none: "none",
  photo: "photo",
  code: "code",
  jobCode: "jobCode",
  employeeID: "employeeID",
  workBarCode: "workBarCode",
  trainingBarCode: "trainingBarCode",
  firstName: "firstName",
  middleName: "middleName",
  lastName: "lastName",
  suffix: "suffix",
  dob: "dob",
  yob: "yob",
  selectedTagList: "selectedTagList",
  electorId: "electorId",
  userId: "userId",
  workerStatusId: "workerStatusId",
  homeSubdivisionId: "homeSubdivisionId",
  ssn: "ssn",
  electionsWorked: "electionsWorked",
  workerTypeId: "workerTypeId",
  workerTypeUpload: "workerTypeUpload",
  electionInspector: "electionInspector",
  partyId: "partyId",
  raceId: "raceId",
  preferredPronousId: "preferredPronousId",
  spanishLanguageProficiency: "spanishLanguageProficiency",
  workerHearAboutId: "workerHearAboutId",
  residentialAddress: "residentialAddress",
  postalAddress: "postalAddress",
  boundaryType: "boundaryType",
  citizenshipDocuments: "citizenshipDocuments",
  languageIdList: "languageIdList",
  hadESALanguageProficiency: "hadESALanguageProficiency",
  certificate: "certificate",
  certificationUploaded: "certificationUploaded",
  certifiedBy: "certifiedBy",
  workTravelId: "workTravelId",
  jobIdList: "jobIdList",
  workerTypeUploadModel: "workerTypeUploadModel",
  eleCheck: "eleCheck",
  ssnCheck: "ssnCheck",
  ssnCheckBy: "ssnCheckBy",
  volunteer: "volunteer",
  interestingIn: "interestingIn",
  orientationCompletedPastSixMonth: "orientationCompletedPastSixMonth",
  interestingInTime: "interestingInTime",
  workerAvailableShiftList: "workerAvailableShiftList",
  interestingInEarlyVoting: "interestingInEarlyVoting",
  closedDayList: "closedDayList",
  electionEarlyDay: "electionEarlyDay",
  megansLaw: "megansLaw",
  completedBy: "completedBy",
  additionalNotes: "additionalNotes",
  languageId: "languageId",
  preferredContactMethod: "preferredContactMethod",
  workerPortalUtilized: "workerPortalUtilized",
  useEmail: "useEmail",
  email: "email",
  useSMS: "useSMS",
  sms: "sms",
  usePhone: "usePhone",
  phone: "phone",
  isUseNoDisturbHours: "notDisturb",
  noDisturbHours: "noDisturbHours",
  workPhone: "workPhone",
  additionalPhoneNumber: "additionalPhoneNumber",
  asWorkerPastThreeYear: "asWorkerPastThreeYear",
  isWorkerOnTheList: "isWorkerOnTheList",
  municipalityBoundaryIds: "municipalityBoundaryIds"
}

function findField(fieldName, ticketFields) {
  if (!ticketFields) {
    return null;
  }
  let itemObj = null;
  for (const item of ticketFields) {
    if (item.fieldName === fieldName) {
      itemObj = item;
      break;
    }
  }
  return itemObj;
}

export const getFieldOrderIndex = (fieldName, ticketFields) => {
  const field = findField(fieldName, ticketFields);
  return field?.order ?? 0;
};

export const getFieldDisplayName = (fieldName, ticketFields) => {
  const field = findField(fieldName, ticketFields);
  return field?.displayName ?? "";
};

export const getFieldIsHidden = (fieldName, ticketFields, type) => {
  const field = findField(fieldName, ticketFields);
  if (field) {
    const profile = type == workerModuleType.workerPortalApply ? field.workerPortalElectionApply : field.workerPortalApplication;
    return profile.isHidden;
  }
  return true;
};

export const getFieldIsRequired = (fieldName, ticketFields, type) => {
  const field = findField(fieldName, ticketFields);
  if (field) {
    const profile = type == workerModuleType.workerPortalApply ? field.workerPortalElectionApply : field.workerPortalApplication;
    return profile.isMandatory;
  }
  return true;
};

export const getFieldIsViewOnly = (fieldName, ticketFields, type) => {
  const field = findField(fieldName, ticketFields);
  if (field) {
    const profile = type == workerModuleType.workerPortalApply ? field.workerPortalElectionApply : field.workerPortalApplication;
    return profile.isViewOnly;
  }
  return true;
};


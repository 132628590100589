import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getTags = () => {
  return http.get(`api/tags/drop-down`, {}, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};
export const anonGetTags = () => {
  return http.get(`/anon/tags/drop-down`, {}, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
  });
};

import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getClientName = (id) => {
  const data = { workerTypeId: id };
  return http.get("/anon/worker-type-client-name", data, { loading: false, useBody: true });
};

export const getLoadingPageTitle = (data) => {
  return http.get("/anon/portal-page/setting/loading-page", data);
};

export const getLoadingPage = () => {
  return http.get("/anon/portal-page/setting/worker-type");
};

export const getWorkerTypeExFields = (id, data) => {
  return http.get(`/anon/portal-page/${id}/extfields`, data, {});
};

export const getWorkerExFields = (data) => {
  return http.get("/anon/portal-page/extfields", data, { loading: true });
};

export const getWorkerProfileSettings = () => {
  return http.get("/anon/worker-profile/settings", null, { loading: false });
};

export const getPreferenceExFields = (data) => {
  return http.get("/anon/portal-page/preference-extfields", data, { loading: true });
};

export const checkWorktypeAge = (data) => {
  return http.get("/anon/portal-page/actions/check-worktype-age", data, { loading: false, useBody: true });
};

export const getPreferredMethod = (data) => {
  return http.get("/anon/enum/preferred-contact-method", data, { loading: false, useBody: true });
};

export const regWorker = (data) => {
  return http.post("/anon/worker/reg", data, { loading: false, useBody: true });
};

export const getStreetType = () => {
  return http.get("/anon/enum/street-types", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getStreetPost = () => {
  return http.get("/anon/enum/street-posts", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getStateType = () => {
  return http.get("/anon/enum/state-types", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getPartys = () => {
  return http.get("/anon/party/list", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const searchElectorVoter = (data) => {
  return http.post("/anon/worker/elector-voter", data, { useBody: true, loading: true });
};

export const getRaceOptions = () => {
  return http.get("/anon/worker/race/drop-models", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

//searchVoter
export const getSearchVoterKeys = (data) => {
  return http.get("/anon/worker/search-voter-field", data);
};

export const getworkerportalpagesetting = (data) => {
  return http.post("/anon/getworkerportalpagesetting/getsetting", data, { useBody: true, loading: true });
};

export const searchHomeElectorVoter = (data) => {
  return http.post("api/home/elector-voter", data, { useBody: true, loading: true });
};

export const getBoundaryPage = (data) => {
  return http.post("/anon/worker/boundary-page", data, { useBody: true, loading: true });
};

export const getVoterBoundaryType = (data) => {
  return http.get(`/anon/worker/voter-boundary-type`, data);
};

export const getSearchSubdivisionPage = (data) => {
  return http.post("/anon/worker/subdivision-page", data, {
    useBody: true,
    loading: true,
    headers: { "Content-Type": "application/json-patch+json" },
  });
};

export const rollValidationRequired = (data) => {
  return http.get("/anon/worker/roll-validation-required", data);
};

export const searchVoterRequired = (id) => {
  return http.get("/anon/worker/search-voter-required", { id: id });
};

export const checkEmailAddressUsed = (data) => {
  return http.post(`/anon/worker/check-email-used`, data, { useBody: true, loading: false });
};

export const checkSSNUsed = (data) => {
  return http.post(`/anon/worker/check-ssn-used`, data, { useBody: true, loading: false });
};

export const GetMunicipalityBoundary = () => {
  return http.get("/anon/municipality/boundary-list");
};

export const getVolunteerByWorkerTypeId = (workerTypeId) => {
  return http.get(`/anon/worker/Volunteer/${workerTypeId}`, {}, { loading: false });
};

export const getHearAbout = (data) => {
  return http.get(`/anon/worker/hearabouts`, data, { loading: false });
};

export const getStudentId = (data) => {
  return http.get("/anon/worker/workertype-student", data);
};

export const getCountyemployeeId = (data) => {
  return http.get("/anon/worker/workertype-countyemployee", data);
};

export const checkElectorId = (data) => {
  return http.get("api/home/check-elector-id", data, { loading: true });
};

export const getUploadConfig = (id) => {
  return http.get(`anon/upload-config/${id}`, null, { loading: false });
};

export const uploadFile = (data) => {
  return http.post("anon/docs", data, {
    loading: false,
    useBody: true,
  });
};

export const multiUploadFile = (data) => {
  return http.post("anon/docs/multi", data, {
    loading: false,
    baseURL: globalEnv.VUE_APP_STD_URL,
    useBody: true,
  });
};

export const getSSNSettings = () => {
  return http.get("/anon/ssn-setting", null, { loading: false });
};

export const getJobCode = () => {
  return http.get("/anon/worker/get-job-code", null, { loading: false });
};

export const checkJobCode = (data) => {
  return http.post("/anon/worker/actions/check-jobcode-used", data, { loading: false });
};

export const getMultipleWorkDateShiftTypesOptions = () => {
  return http.get("/api/get-workeravailableshiftlist", null, { loading: false });
};

import { storeage } from "fe-infrastractures";

export const handleApt = (address) => {
  if (typeof storeage.getSystemGeneralInfo().aptBeginWith === "string") {
    let aptBeginWith = storeage.getSystemGeneralInfo().aptBeginWith;
    if (
      aptBeginWith &&
      aptBeginWith.length &&
      address.apartment.length
    ) {
      if (
        !address.apartment
          .toLowerCase()
          .startsWith(aptBeginWith.toLowerCase())
      ) {
        address.apartment = aptBeginWith.concat(
          address.apartment
        );
      } else {
        let regex = new RegExp(aptBeginWith, "i");
        address.apartment =
          address.apartment.replace(
            regex,
            aptBeginWith
          );
      }
    }
  }
}

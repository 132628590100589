<template>
  <div>
    <div class="content">
      <div class="pop-flex mb-2">
        <k-form-group label-suffix="name" />
        <k-form-input v-model="name" class="mr-1" />
        <k-button @click="search"> buttons.search </k-button>
      </div>
      <k-alert-msg ref="lblMsg" />
      <konn-grid
        id="grid"
        :select-mode="selectMode"
        :has-check-box="hasCheckBox"
        :has-action="false"
        ref="selectableTable"
        :data-source="dataSource"
        :get-condition="getCondition"
        @row-selected="onRowSelected"
        :show-all-checkbox="false"
        :fields="getFields"
      />
      <div class="navBottom-btn-group" />
    </div>
  </div>
</template>

<script>
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

export default {
  props: {
    selectMode: {
      type: String,
      default: "single",
    },
    getApi: {
      type: Function,
      default: null,
    },
    condition: {
      type: Object,
      default: null,
    },
    ShowPopwindowText: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    ShowPopwindowTextEnum: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      seen: true,
      isActive: true,
      isRight: false,
      name: "",
      selected: [],
      hasCheckBox: true,
      fields: this.switchShowPopwindowText(this.ShowPopwindowText, []),
      SubdivisionFields: [
        {
          key: "DivisionCode",
          label: "id",
          sortable: true,
        },
        {
          key: "DivisionName",
          label: "name",
          sortable: true,
          width: "20%",
        },
        {
          key: "Status",
          label: "status",
          sortable: false,
        },
      ],
      BoundaryTypeFields: getConfigVal(
        curPageConfigAndDefaultPageConfig,
        "grid",
        "boundaryTypeFields"
      ),
    };
  },
  computed: {
    dataSource: function () {
      return this.getApi;
    },
    getFields() {
      return this.switchShowPopwindowText(this.ShowPopwindowText, []);
    },
  },
  methods: {
    switchShowPopwindowText(e, fields) {
      switch (e) {
        case this.ShowPopwindowTextEnum.Subdivision:
          this.fields = this.SubdivisionFields;
          break;
        case this.ShowPopwindowTextEnum.BoundaryType:
          this.fields = this.BoundaryTypeFields;
          break;
        default:
          this.fields = fields;
          break;
      }
      return this.fields;
    },
    getSelected: function () {
      let items = this.selected;
      if (items && items.length > 0) {
        return items;
      } else {
        alert(this.$g("const.warning.select-records"));
      }
    },
    getCondition() {
      return {
        divisionName: this.name,
        boundaryName: this.name,
        ...this.condition,
      };
    },
    onRowSelected(items) {
      this.selected = items;
    },
    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },
  },
};
</script>

<style lang="scss">
.hand:hover {
  cursor: pointer;
}
</style>
